<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <div class="content"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import config from "../config";
export default {
  name: "indexPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage },
  data() {
    return {};
  },
  methods: {
    async init() {
      await this.getNexwork();
      if (await this.getAppInfo(true)) {
        await this.getStorageEvent();
        this.getMerchantDetail();
        this.getLocation();
        this.setStatusBarStyleLight();
        if (this.$store.state.form.token) {
          if (this.$store.state.connected) {
            await this.refreshTokenEvent();
            this.getMemberDetail(false, 2);
          } else {
            this.$router.replace({
              path: "/main/landing",
              // path: "/wallet",
              // query: {
              //   type: 2,
              // },
            });
          }
        } else {
          if (this.$store.state.appVersion) {
            if (this.$store.state.connected || this.$store.state.form.listHomePage) {
              if (!localStorage.getItem("showUserGuide")) {
                this.$router.replace({
                  path: "/userGuide",
                });
              } else {
                this.$router.replace({
                  path: "/main/landing",
                });
              }
            } else {
              this.inter = setInterval(() => {
                if (this.$store.state.connected) {
                  clearInterval(this.inter);
                  if (!localStorage.getItem("showUserGuide")) {
                    this.$router.replace({
                      path: "/userGuide",
                    });
                  } else {
                    this.$router.replace({
                      path: "/main/landing",
                    });
                  }
                }
              }, 300);
            }
          } else {
            this.$router.replace({
              path: "/register",
            });
          }
        }
      }
    },
  },
  ionViewDidEnter() {
    this.init();
  },
};
</script>
